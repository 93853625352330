import './src/styles/global.css'
import "@fontsource/ibm-plex-sans"

export const onClientEntry = () => {
  setTimeout(() => {
    if (document.getElementById("loader-wrapper")) {
      document.getElementById("loader-wrapper").style.display = "flex";
    }
  }, 0);
};

export const onInitialClientRender = () => {
  setTimeout(() => {
    if (document.getElementById("loader-wrapper")) {
      document.getElementById("loader-wrapper").style.display = "flex";
    }
  }, 0);
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (document.getElementById("loader-wrapper")) {
      document.getElementById("loader-wrapper").style.display = "none";
    }
  }, 3500);
};